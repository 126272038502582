import React, { createContext, useContext, useState,useEffect } from "react";
import { getThemeSettingByUname } from "../APIServices/ThemeAPI";

const ThemeContext = createContext();

export const useThemes = () => useContext(ThemeContext);

const ThemeProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState("dark");
  const usernames = ['VNDArwin.Parameswaran', 'Macnamara, Ed', 'Schulz, Douglas', 'VNDPrasanna.Asokan', 'VND.AdilakshmyT', 'VND.NandhiniT', 'VND.NithyaN', 'VND.VigneshR'];
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(''); 

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  
  useEffect(() => {
    const fetchTheme = async () => {
        if (!user) return; 
        try {
            const retrievedTheme = await getThemeSettingByUname(user);
            if (retrievedTheme) {
                setTheme(retrievedTheme);
            }
        } catch (error) {
            console.error('Error fetching theme:', error);
        }
    };

    fetchTheme(); 
}, [user]); 

  return (
    <ThemeContext.Provider value={{user,setUser, profilePicture, setProfilePicture,theme,setTheme,loading, setLoading ,usernames,snackbarOpen, setSnackbarOpen,snackbarMessage, setSnackbarMessage,handleCloseSnackbar}}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

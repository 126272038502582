import React, { createContext, useState, useContext ,useEffect,useRef} from 'react';
import { retrieveImages,generateImage } from '../APIServices/ChatAPI';
import { toast } from 'react-toastify';
const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

const ChatProvider = ({ children }) => {
    const [user, setUserChat] = useState(null);
    const [imageData, setImageData] = useState([]);
    const [conversation, setConversation] = useState([]);
    const [selectedSize, setSelectedSize] = useState(null); 
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const chatConversationRefnew = useRef(null);
    const scrollContainerRef = useRef(null);
    
    const scrollToTop = () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      };

    const scrollToChatConversation = () => {
        if (chatConversationRefnew.current) {
          chatConversationRefnew.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };
      
      const handleSendMessage = async (message = null, title = null, size = null) => {
        if (isLoading) {
          return; 
      }
        scrollToChatConversation();
        setIsLoading(true);
        try {
          if (conversation.length > 0) {
            setImageData((prev) => [
              ...conversation, 
              ...prev,
            ]);
          }
          const response = await generateImage(user, title || message, size || selectedSize);
      
          if (response) {
            const imageResponse = {
              id: response.id,
              prompt: response.prompt,
              images:response.images,
              username:response.username
            };
            setConversation([imageResponse]);
          } else {
            throw new Error("No image generated. Please try again.");
          }
         } catch (error) {
          console.error("Error generating image or updating conversation:", error);
          
          if (error.response?.data?.error?.code === "content_policy_violation") {
            toast.error("Your request violates the content policy. Please try a different prompt.");
          } else {
            toast.error("Oops! Something went wrong. Please try again.");
          }
        } finally {
          setIsLoading(false);
        }
      };


    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                setLoading(true);
                const data = await retrieveImages(user);
                const sortedData = data.sort((a, b) => {
                    // Prefer `Updated_At` if available, otherwise use `Created_At`
                    const dateA = a.Updated_At ? new Date(a.Updated_At) : a.Created_At ? new Date(a.Created_At) : null;
                    const dateB = b.Updated_At ? new Date(b.Updated_At) : b.Created_At ? new Date(b.Created_At) : null;
    
                    if (dateA && dateB) return dateB - dateA;
                    if (!dateA) return 1;
                    if (!dateB) return -1;
                    return 0;
                });
                setImageData(sortedData);
                setLoading(false);
            };
            fetchData();
        }
    }, [user]);
    
    
    return (
        <ChatContext.Provider value={{setUserChat, handleSendMessage,message, setMessage,imageData, setImageData,conversation, setConversation, selectedSize, setSelectedSize,loading ,isLoading, setIsLoading,scrollToChatConversation,chatConversationRefnew,scrollToTop,scrollContainerRef}}>
            {children}
        </ChatContext.Provider>
    );
};

export default ChatProvider;

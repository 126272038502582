import React from 'react';
import { HashRouter } from 'react-router-dom';
import AppRoutes from './router/AppRoutes'; 
import { ToastContainer } from 'react-toastify';
import CustomSnackbar from './components/ReuseComponets/CustomSnackbar';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <HashRouter>
      <CustomSnackbar/>
     <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          pauseOnHover
          draggable
          theme="colored"
        />
      <AppRoutes />
    </HashRouter>
  );
}

export default App;
